.SignupButton {
    background: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4 1.6rem;
    color: #000;
    width: 149px;
    /* border-radius: 4px; */
}
.Button {
    font-size: 22px;
    color: #000;
    width: 100%;
    display: block;
    text-align: center;
    /* border-radius: 4px; */
    background: #fff;
    padding: 16px 22px;
}
.Button a,
.SignupButton a,
.ButtonSlim a
 {
    text-decoration: none;
    color: #000;
}
.SecondaryButton a {
    text-decoration: none;
    color: #fff;
}
.SecondaryButton {
    font-size: 22px;
    border: 1px solid #fff;
    padding: 16px 22px;
    color: #fff;
    width: 144px;
    display: block;
    text-align: center;
    /* border-radius: 4px; */
}
.ButtonSlim {
    font-size: 18px;
    /* width: 122px; */
    /* width: 100%; */
    display: block;
    text-align: center;
    /* border-radius: 4px; */
    background: #fff;
    color: #000;
    padding: 16px 22px;
}
.SecondarySlim {
    font-size: 18px;
    /* width: 122px; */
    display: block;
    text-align: center;
    /* border-radius: 4px; */
    border: 1px solid #fff;
    color: #fff;
    padding: 16px 22px;
}
@media (max-width: 499px) {
    .Button, .SecondaryButton {
        width: 90%;
    }
}