.TimelineDetail__Bg {
    background: url('../../assets/tulsa-bg.png') center center no-repeat;
    background-size: cover;
    z-index: 1;
    height: 100%;
}
.TimelineDetail__Overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 2;
}
.TimelineDetail__Content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 0;
}

.TimelineDetail__Breadcrumbs {
    display: flex;
}
.TimelineDetail__Crumb {
    display: flex;
    align-items:center;
}
.TimelineDetail__Crumb {
    font-family: Helvetica;
    font-size: 14px;
    line-height: 14px;
    color: #bfbfbf;
}
.TimelineDetail__Crumb--current {
    color: #fff;
    font-weight: bold;
}
.TimelineDetail__Crumb img {
    margin: 0 6px;
}
.TimelineDetail__Header {
    font-family: 'Timmons NY', sans-serif;
    font-size: 152px;
}
.TimelineDetail__TimelineLayout {
    display: flex;
}
.TimelineDetail__ActiveDate {
    font-family: 'Timmons NY', sans-serif;
    font-size: 120px;
    line-height: 116px;
}
.TimelineDetail__ActiveContent {
    margin-top: 12px;
    /* width: calc(45% - 10px); */
}
.TimelineDetail__ActiveContent h1 {
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 59px;
    line-height: 57px;
    margin: 0;
    margin-bottom: 20px;
}
.TimelineDetail__ActiveContent p {
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    margin: 0;
}
.TimelineDetail__Controls {
    padding: 26px 20px 0 0;
    display: flex;
    flex-direction: column;
}
.TimelineDetail__Next {
    padding-bottom: 6px;
    cursor: pointer;
}
.TimelineDetail__Previous {
    transform: rotate(180deg);
    cursor: pointer;
}
.TimelineDetail__Marker {
    min-width: 60px;
    height: 2px;
    margin: 0 16px 0 0px;
    background: #fff;
}
.TimelineDetail__Marker--active {
    background: #FF4645;
    height: 2px;
    margin: 0 16px 0 0px;
    min-width: 120px;
}
.TimelineDetail__Marker--invisible {
    opacity: 0;
}
.TimelineDetail__NextDate {
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.TimelineDetail__MidDate {
    display: flex;
    align-items: center;
    padding: 20px 0;
}
.TimelineDetail__TimelineDates {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.TimelineDetail__Active {
    display: flex;
    align-items: center;
    margin-right: 20px;
}
.TimelineDetail__DateGroup {
    padding-top: 20px;
}
.TimelineDetail__DateGroup:nth-child(3),
.TimelineDetail__DateGroup:nth-child(6)
 {
    padding-bottom: 18px;
}
.TimelineDetail__DateGroup a,
.TimelineDetail__Link {
    text-decoration: none;
    color: #fff;
}
