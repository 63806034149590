.Timeline__Wrapper {
    color: #fff;
    max-width: 1366px;
    margin: 0 auto;
    padding: 0 3rem;
}
.TimelinePreview__H1 {
    font-family: 'Timmons NY', sans-serif;
    font-size: 188px;
    line-height: 166px;
    /* line-height: 188px; */
    /* max-width: 861px; */
}
.TimelinePreview__Timeline {
    display: flex;
    justify-content: flex-start;
}
.TimelinePreview__FocusDate {
    font-family: 'Timmons NY', sans-serif;
    font-size: 110px;
    line-height: 106px;
}
.TimelinePreview__TimelineContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 670px;
}
.TimelinePreview__TimelineContent h1 {
    font-family: Helvetica;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}
.TimelinePreview__TimelineContent p {
    font-family: Helvetica;
    font-weight: normal;
    margin: 0;
    color: #bfbfbf;

    padding: 10px 50px 0 0;
}

.TimelinePreview__ActiveDate {
    display: flex;
    align-items: center;
}
.TimelinePreview__Marker {
    min-width: 60px;
    height: 4px;
    margin: 0 16px 0 0px;
    background: #fff;
}
.TimelinePreview__Marker--active {
    background: #FF4645;
}
.TimelinePreview__Marker--invisible {
    opacity: 0;
}
.TimelinePreview__NextDate {
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.TimelinePreview__TimelineTextWrapper{
    display: flex;
    align-items: center;
}
.TimelinePreview__Controls {
    padding: 26px 20px 0 0;
}
.TimelinePreview__Previous {
    padding-bottom: 6px;
    cursor: pointer;
}
.TimelinePreview__Previous--disabled,
.TimelinePreview__Next--disabled
 {
    opacity: 0.3;
}
.TimelinePreview__Next {
    transform: rotate(180deg);
    cursor: pointer;
}
.TimelinePreview__ActiveContent {
    cursor: pointer;
}
.TimelinePreview__TimelineImage img {
    width: 646px;
    max-width: 100%;
}

@media (max-width: 499px) {
    .TimelinePreview__H1 {
        font-size: 142px;
        line-height: 142px;
    }
    .TimelinePreview__Timeline {
        flex-wrap: wrap-reverse;
    }
    .TimelinePreview__Controls {
        width: 100%;
        justify-content: space-between;
    }
}
