.Donate__Wrapper {
    background: #000;
}

.Donate__Wrapper .header {
    font-family: 'Timmons NY', sans-serif;
    font-size: 220px;
    line-height: 220px;
}

.Donate__Wrapper {
    max-width: 1366px;
    margin: 0 auto;
    padding: 0 3rem 13rem;
}
.Donate__Hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Donate__Hero p {
    max-width: 600px;
    color: #bfbfbf;
}
.DonateSection__DonateContent p {
    color: #bfbfbf;
}
.Donate__Hero .hero-image {
    width: 390px;
    max-width: 100%;
}

@media (max-width: 499px) {
    .Donate__Wrapper {
        padding: 0 1rem 10rem;
    }
    .Donate__Hero {
        flex-wrap: wrap;
    }
    .Donate__Wrapper .header {
        font-size: 153px;
        line-height: 153px;
    }
}