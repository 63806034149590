.App {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', sans-serif;
  color: #ffffff;
  min-width: 100%;
}

.App__Content {
  max-width: 1200px;
  margin: 0 auto;
}

p {
  font-size: 16px;
  line-height: 22px;
}

/* @font-face {
  font-family: 'Timmons NY';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Timmons_NY_Timmons_NY.ttf');
  src: local('Timmons_NY_Timmons_NY'),
  url('./fonts/Timmons_NY_Timmons_NY.ttf');
}

@font-face {
  font-family: 'Crimson Text';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/CrimsonText-Regular.ttf');
} */
