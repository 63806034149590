.BusinessSpotlight__Layout {
    display: flex;
    align-items: flex-end;
}
.BusinessSpotlight__Layout img {
    /* height: 340px; */
    width: 600px;
    max-width: 100%;
}
.BusinessSpotlight__Layout .content {
    padding-left: 75px;
}
.BusinessSpotlight__Layout .content h1 {
    font-size: 26px;
    margin: 0;
    padding-bottom: 15px;
    padding-top: 40px;
}
.BusinessSpotlight__Layout .content p {
    font-size: 26px;
    line-height: 36px;
    color: #bfbfbf;
    margin: 0;
}

@media (max-width: 499px) {
    .BusinessSpotlight__Layout .content {
        padding-left: 0;
    }
    .BusinessSpotlight__Layout .content h1 {
        font-size: 22px;
        padding-bottom: 10px;
    }
    .BusinessSpotlight__Layout .content p {
        font-size: 18px;
        line-height: 26px;
    }
}