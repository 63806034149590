.Header__Bg {
    background: #000000;
    margin: 0;
    padding: 0;
}

.Header__Wrapper {
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1366px;
    margin: 0 auto;
}

.Header__MainLinks {
    display: flex;
    align-items: center;
}

.Header__MobileHamburger {
    display: none;
}

.Header__Desktop {
    padding: 0.6rem 0;
}

.Header__AuthLinks {
    display: flex;
}

.Header__Wrapper .Logo {
    width: 260px;
    margin-right: 50px;
    margin-top: 6px;
}

.Header__Link {
    padding: 0.6rem 0;
    color: #fff;
    text-decoration: none;
    opacity: 0.7;
    font-size: 18px;
    margin-right: 50px;
}

.Header__LinkLogin {
    padding: 0.6rem 1.75rem;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
}

.Header__Link--active {
    font-weight: bold;
    opacity: 1;
}
.Header__MobileHamburger--open,
.Header__MobileHamburger--closed {
    display: none;
}
@media (max-width: 499px) {
    .Header__Desktop,
    .Header__AuthLinks {
        display: none;
    }
    .Header__Wrapper {
        padding: 1rem;
    }
    .Header__MobileHamburger--open,
    .Header__MobileHamburger--closed {
        display: block;
        width: 26px;
        height: 26px;
        /* transition: ease-in-out .4s; */
    }
    .Header__MobileHamburger--open .Header__MobileHamLine:first-child,
    .Header__MobileHamburger--closed .Header__MobileHamLine:first-child,
    .Header__MobileHamburger--open .Header__MobileHamLine:nth-child(2),
    .Header__MobileHamburger--closed .Header__MobileHamLine:nth-child(2),
    .Header__MobileHamburger--open .Header__MobileHamLine:last-child,
    .Header__MobileHamburger--closed .Header__MobileHamLine:last-child
     {
        transition: ease-in-out .4s;
    }
    .Header__MobileHamburger--open .Header__MobileHamLine:first-child {
        transform: rotate(45deg);
    }
    .Header__MobileHamburger--closed .Header__MobileHamLine:first-child,
    .Header__MobileHamburger--closed .Header__MobileHamLine:last-child{
        transform: rotate(0);
    }
    .Header__MobileHamburger--open .Header__MobileHamLine:nth-child(2) {
        display: none;
    }
    .Header__MobileHamburger--closed .Header__MobileHamLine:nth-child(2) {
        display: block;
    }
    .Header__MobileHamburger--open .Header__MobileHamLine:last-child {
        transform: rotate(-45deg);
        margin-top: -7px;
    }
    .Header__MobileHamburger--closed .Header__MobileHamLine:last-child {
        margin-top: 0;
    }
    .Header__MobileHamLine {
        height: 1px;
        background: #fff;
        margin-bottom: 6px;
    }
    .Header__MobileMenu {
        height: calc(100vh - 124px);
        transition: ease-in-out .4s;
    }
    .Header__Mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .Header__Link {
        margin-right: 1rem;
    }
    .Header__MobileMenu--closed {
        height: 0;
        transition: ease-in-out .4s;
    }
}