.Main {
    color: #fff;
    background: #000000;
    margin: 0 auto;
}
.Main__Header {
    display: flex;
    align-items: center;
    
    margin: 0 auto;
    padding: 1rem 3rem 2rem;
    font-family: 'Timmons NY', sans-serif;
    max-width: 1366px;
    height: 200px;
}
.Main__HeaderLg {
  font-size: 222px;
  line-height: 222px;
 margin: 0;
 padding: 0;
  /* font-size: 220px; */
  /* margin-right: 2rem; */
}
.Main__HeaderSm {
  /* font-size: 122px;
  line-height: 103px; */
  font-size: 106px;
  line-height: 90px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 2rem;
}
.Main__HeaderLg--red {
  background: #ff4645;
  padding: 0 1.5rem;
  margin-left: 24px;
}
.Main__BannerImages {
  margin: 0 auto;
  padding: 0 3rem;
  display: flex;
  align-items: flex-start;
  max-width: 1366px;
}
.Main__BannerImgLg {
  /* width: 736px; */
  /* height: 300px; */
  height: 340px;
  margin-right: 3rem;
}
.Main__BannerImgSm {
  height: 340px;
  margin-right: 3rem;
  /* padding-left: 20px; */
}
.Main__CalloutWrapper {
  padding: 280px 3rem;
  max-width: 1366px;
  margin: 0 auto;
}
.Main__BannerText {
  font-family: "Crimson Text", serif;
  font-size: 13px;
  max-width: 526px;
  margin-top: 0.6rem;
}
.Main__CalloutText {
  font-family: "Timmons NY", sans-serif;
  font-size: 188px;
  line-height: 166px;
  /* line-height: 188px; */
  /* max-width: 810px; */
}
.Main__CalloutText--red {
  background: #ff4645;
  padding: 0 1rem;
}
.Main__FocusWrapper {
  padding: 200px 0;
  max-width: 1200px;
  margin: 0 auto;
}
.Main__FocusPoint {
  display: flex;
  align-items: center;
  padding: 120px 0;
  max-width: 1366px;
}
.Main__FocusPointHeader {
  font-family: "Timmons NY", sans-serif;
  /* font-size: 204px;
  line-height: 204px; */
  font-size: 180px;
  line-height: 153px;
}
.Main__FocusPointHeaderCustom {
  font-family: "Timmons NY", sans-serif;
  font-size: 180px;
  line-height: 153px;
}
.Main__FocusImg {
  max-width: 100%;
  width: 600px;
}
.Main__PaddingRight20 {
  padding-right: 40px;
}
.Main__FocusContent {
  padding: 0 40px;
}
.Main__FocusContent p {
  font-family: "Helvetica Neue", sans-serif;
  color: #bfbfbf;
}
.Main__FocusGraphLabel {
  font-family: "Crimson Text", serif;
  font-size: 17px;
}
.Main__FocusGraphCitationWrapper {
  padding-top: 10px;
  /* max-width: 1200px; */
}
.Main__FocusGraphCitation {
  font-family: "Helvetica Neue", sans-serif;
  color: #bfbfbf;
  font-size: 12px;
}

.Main__BusinessesWrapper {
  max-width: 1200px;
  margin: 0 auto;
}
.Main__BusinessesHeader {
  font-family: "Timmons NY", sans-serif;
  font-size: 180px;
}

.Main__DonateWrapper {
  padding: 200px 0;
  max-width: 1200px;
  margin: 0 auto;
}
.Main__DonateHeaderMain {
  font-family: "Timmons NY", sans-serif;
  font-size: 187px;
}
.Main__DonateHeader {
  font-family: "Timmons NY", sans-serif;
  font-size: 187px;
  z-index: 100;
  /* width: 1000px; */
  position: absolute;
}

@media (max-width: 499px) {
  .Main__Header,
  .Main__BannerImages,
  .Main__FocusPoint {
    flex-wrap: wrap;
  }

  .Main__HeaderLg {
    font-size: 148px;
    line-height: 138px;
  }
  .Main__Header {
    height: auto;
    padding: 1rem;
  }
  .Main__BannerImgSm {
    display: none;
  }
  .Main__BannerImgLg {
    height: 200px;
  }
  .Main__FocusPointHeader {
    font-size: 153px;
    line-height: 138px;
  }
  .Main__HeaderLg--red {
    margin-top: 20px;
  }
  .Main__FocusContent {
    padding: 0 20px;
  }
  .Main__DonateWrapper {
    padding: 200px 20px;
  }
  .Main__FocusPointHeaderCustom {
    font-size: 115px;
    line-height: 120px;
  }
  .Main__FocusGraphWrapper,
  .Main__BannerImages,
  .Timeline__Wrapper {
    padding: 0 1rem;
  }
  .Main__BusinessesHeader {
    font-size: 153px;
  }
  .Main__CalloutWrapper {
    padding: 260px 1rem;
  }
  .Main__BusinessesWrapper {
    padding: 0 20px;
  }
  .Main__HeaderLg--red {
    margin-left: 0;
  }
  .Main__DonateHeader {
    font-size: 150px;
    line-height: 152px;
    position: relative;
  }
  
}
