.DonateSection__Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    padding: 200px 0;
}
.DonateSection__DonateContent {
    position: relative;
}
.DonateSection__DonateImgImg {
    position: relative;
    /* left: 190%; */
    width: 414px;
    max-width: 100%;
}
.DonateSection__DonateText {
    padding-top: 100px;
}
.DonateSection__DonateHeader {
    font-family: "Timmons NY", sans-serif;
    font-size: 187px;
    line-height: 187px;
    z-index: 100;
    /* position: relative; */
}
.DonateSection__DonateContent p {
    max-width: 500px;
}
.DonateSection__ButtonWrapper {
    margin-top: 30px;
}

@media (max-width: 499px) {
    .DonateSection__Wrapper {
        flex-wrap: wrap;
    }
    .DonateSection__DonateHeader {
        font-size: 153px;
        line-height: 153px;
    }
    .DonateSection__DonateContent {
        /* margin-top: calc(190%); */
    }
    .DonateSection__DonateImgImg {
        margin-top: 80px;
    }
}