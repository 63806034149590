.BusinessDetail__Bg {
    background: #000;
}
.BusinessDetail__Wrapper {
    max-width: 1366px;
    margin: 0 auto;
    padding: 3rem;
}
.BusinessDetail__Header {
    font-family: 'Timmons NY', sans-serif;
    font-size: 120px;
}
.BusinessDetail__Row {
    display: flex;
}
.BusinessDetail__Placeholder {
    background: #fcfcfc;
    display: block;
    width: 500px;
    height: 400px;
}
.BusinessDetail__Info {
    margin-bottom: 30px;
}
.BusinessDetail__Info .owner .value {
    margin: 0;
}
.BusinessDetail__Info .value {
    color: #bfbfbf;
}
.BusinessDetail__Content {
    padding-left: 2rem;
}
.BusinessDetail__Info .owner {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.BusinessDetail__Info .owner img {
    margin-right: 4px;
}
.BusinessDetail__Info .label {
    font-weight: bold;
}