.Businesses__Bg {
    background: #000;
}
.Businesses__Wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 60px;
}
.Businesses__Wrapper .header {
    font-family: 'Timmons NY', sans-serif;
    font-size: 220px;
}
.Businesses__CardsRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
}
.Businesses__SectionWrapper {
    padding: 40px 0 0;
}
.BusinessCategories__Wrapper {
    padding-top: 30px;
}
.BusinessCategories {
    display: flex;
    justify-content: space-between;
    /* padding: 25px 0; */
    width: 100%;
}
.BusinessCategories__Header {
    font-size: 24px;
    font-weight: bold;
}
.BusinessCategories__Cat {
    padding: 25px 0;
}
.BusinessCategories__Cat--active {
    font-weight: bold;
    border-bottom: 2px solid #fff;
}
.Businesses__EmptyState {
    color: #fff;
    font-size: 20px;
    text-align: center;
}
.BusinessPage__ButtonGroup {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 60px;
}
.BusinessPage__Next, .BusinessPage__Previous {
    background: #fff;
    border: 1px solid #fff;
    padding: 8px 18px;
    /* border-radius: 3px; */
    margin: 0 3px;
}
button:disabled img {
    opacity: 0.3;
}
.BusinessPage__NextArrow, .BusinessPage__PrevArrow {
    width: 10px;
}
.BusinessPage__PrevArrow {
    transform: rotate(-180deg)
}
.BusinessPage__PagesInfo {
    margin-right: 8px;
}

@media (max-width: 499px) {
    .Businesses__Wrapper {
        padding: 0 20px;
    }
    .Businesses__Wrapper .header {
        font-size: 153px;
        line-height: 153px;
    }
    .BusinessSpotlight__Layout {
        flex-wrap: wrap;
    }
    .BusinessSpotlight__Layout .content {
        padding-left: 0;
    }
    .BusinessSpotlight__Layout .content h1 {
        font-size: 22px;
    }
    .BusinessSpotlight__Layout .content p {
        font-size: 20px;
    }
    .BusinessCategories__Cat {
        font-size: 14px;
        margin-right: 28px;
    }
    .BusinessCategories {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}