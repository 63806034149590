.Main__DonateCaption {
    font-family: 'Helvetica Neue', sans-serif;
    color: #bfbfbf;
    font-size: 20px;
    line-height: 26px;
    max-width: 520px;
}
.Main__DonateText {
    position: relative;
    padding-top: 100px;
}
.Main__DonateTextText {
    position: absolute;
    right: -80px;
}
.Main__DonateSection {
    padding: 300px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}
.Main__DonateImgSection {
    position: relative;
    height: 676px;
    width: 414px;
    max-width: 100%;
}
.Main__DonateImgImg {
    width: 100%;
    height: 100%;
    position: relative;
}
.Main__DonateImgSection .Main__DonateImgImg {
    position: absolute;
    right: 40px;
}
.Main__DonateContent {
    display: flex;
    flex-direction: column;
}
.Main__DonateButtonGroup {
    display: flex;
    align-items: center;
    padding-top: 230px;
}
.Main__DonateButtonMargin {
    margin-right: 60px;
}
@media (max-width: 499px) {
    .Main__DonateTextText {
        display: none;
    }
    .Main__DonateSection {
        flex-direction: column;
    }
    .Main__DonateButtonGroup {
        padding-top: 30px;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 40px;
    }
    .Main__DonateImgSection .Main__DonateImgImg {
        right: 0;
    }
    .Main__DonateButtonMargin {
        margin-right: 0;
        width: 100%;
        margin-bottom: 16px;
    }
}