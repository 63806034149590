.BusinessCard__Wrapper {
    background-color: #222222;
    width: calc(48% - 10px);
    margin: 32px 0;
}
.BusinessCard__Content {
    padding: 40px;
}
.BusinessCard__Content h3 {
    font-size: 22px;
    margin: 0;
}
.BusinessCard__OwnerText {
    font-size: 22px;
    color: #a6a6a6;
    margin: 0;
    display: flex;
    align-items: center;
}
.BusinessCard__Desc {
    font-size: 20px;
    line-height: 26px;
    padding-bottom: 30px;
}
.BusinessCard__OwnerName {
    margin: 0;
}
.BusinessCard__OwnerName {
    margin-right: 10px;
}
.BusinessCard__OwnerRow {
    display: flex;
    margin-right: 6px;
    margin-top: 4px;
}
.BusinessCard__OwnerRow img {
    height: 19px;
    margin-right: 6px;
    padding: 2px;
}
.BusinessCard__BtnRow {
    display: flex;
    align-items: center;
}
.BusinessCard__BtnRow .SecondarySlim {
    margin-left: 16px;
}
.BusinessCard__Banner img {
    width: 100%;
}

@media (max-width: 499px) {
    .BusinessCard__Wrapper {
        width: 100%;
        margin: 12px 0;
    }
    .BusinessCard__Content {
        padding: 20px 20px 30px;
    }
    .BusinessCard__OwnerText {
        font-size: 18px;
    }
    .BusinessCard__Desc {
        font-size: 16px;
        line-height: 22px;
    }
}